















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { CustomerFlowGroupEntityModel, CustomerFlowGroupQueryModel } from './model/customer-flow-group-entity';
import CustomerFlowGroupService from './service/customer-flow-group';
import CustomerFlowGroupLinkDialog from './customer-flow-group-link.vue';

@Component({
    components: {
        'customer-flow-group-link-dialog': CustomerFlowGroupLinkDialog
    }
})
export default class CustomerFlowGroupListComponent extends TableDialogFormComponent<CustomerFlowGroupEntityModel, CustomerFlowGroupQueryModel> {
    CustomerFlowGroupEntityModel = CustomerFlowGroupEntityModel;
    created() {
        this.initTable({
            service: CustomerFlowGroupService,
            queryModel: new CustomerFlowGroupQueryModel(),
            tableColumns: CustomerFlowGroupEntityModel.getTableColumns()
        });
        this.getList();
    }

    linkClick(model: CustomerFlowGroupEntityModel) {
        (this.$refs.linkDialog as CustomerFlowGroupLinkDialog).dialogOpen(model?.id);
    }

    downloadExcelClick() {
        CustomerFlowGroupService.downloadExcelTemplate();
    }

    importExcelChange(fileList) {
        if (fileList && fileList.length > 0) {
            this.startFullScreenLoading('正在导入客流组...');
            return CustomerFlowGroupService.importExcel(_.get(fileList, '[0].file')).then(res => {
                this.showMessageSuccess('导入客流组成功');
            }).finally(() => {
                this.getList();
                this.stopFullScreenLoading();
            });
        }
    }
}

