import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';

export class CustomerFlowGroupEntityModel extends BaseEntityModel {
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '操作',
                width: 200,
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class CustomerFlowGroupQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    toService() {
        return this.getParams();
    }
}

export class CustomerFlowGroupLinkQueryModel extends CustomerFlowGroupQueryModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    toService() {
        const params: any = this.getParams();
        params.params.typeIdentifiers = ['PassengerFlowProbe'];
        return params;
    }
}
