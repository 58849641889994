
import { CustomerFlowGroupEntityModel, CustomerFlowGroupQueryModel, CustomerFlowGroupLinkQueryModel } from '../model/customer-flow-group-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del, download } from '@common-src/service/request';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem`;

class CustomerFlowGroupService implements ICRUDQ<CustomerFlowGroupEntityModel, CustomerFlowGroupQueryModel> {
    async create(model: CustomerFlowGroupEntityModel):Promise<CustomerFlowGroupEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<CustomerFlowGroupEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new CustomerFlowGroupEntityModel().toModel(res);
    }

    async update(model: CustomerFlowGroupEntityModel):Promise<CustomerFlowGroupEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: CustomerFlowGroupEntityModel):Promise<CustomerFlowGroupEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: CustomerFlowGroupQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/custFlowGroup/query `;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new CustomerFlowGroupEntityModel().toModel(item));
        return res;
    }

    async unLinkQuery(query?: CustomerFlowGroupLinkQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/facility/query`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new CustomerFlowGroupEntityModel().toModel(item));
        return res;
    }

    async getLinkedFacility(modelId: string):Promise<any> {
        const url = `${URL_PATH}/custFlowGroup/facility`;
        const res = await post(url, { facilityIds: [modelId] });
        return _.map(_.get(res, '[0].facilityList'), item => item = new CustomerFlowGroupEntityModel().toModel(item));
    }

    async saveLinkRelation(id: string, facilityIds: Array<string>):Promise<any> {
        const url = `${URL_PATH}/save/relation`;
        const res = await post(url, { id, facilityIds });
        return res;
    }

    async importExcel(file):Promise<any> {
        const url = `${URL_PATH}/import`;
        const formData = new FormData();
        formData.append('file', file);
        const res = await post(url, formData);
        return res;
    }

    async downloadExcelTemplate() {
        const url = `${URL_PATH}/export/template`;
        const res = await download(url, null, 'post');
        return res;
    }
}

export default new CustomerFlowGroupService();
