























































import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import TransformComponent from '@common-src/mixins/transform-component';
import { CustomerFlowGroupLinkQueryModel } from './model/customer-flow-group-entity';
import CustomerFlowGroupService from './service/customer-flow-group';

@Component
export default class CustomerFlowGroupLinkDialog extends Mixins(TransformComponent, TableComponent) {
    modelId: string = null;

    created() {
        this.pageSize = null;
        this.isLoadMore = true;
        this.initTable({ listFunc: CustomerFlowGroupService.unLinkQuery, queryModel: new CustomerFlowGroupLinkQueryModel() });
    }

    dialogOpen(id: string) {
        this.modelId = id;
        this.dialogVisible = true;
        this.rightDataList = [];
        this.listData = [];
        this.getList().then(() => {
            CustomerFlowGroupService.getLinkedFacility(this.modelId).then(res => {
                this.rightDataList = _.map(res, item => {
                    return {
                        id: item.id,
                        name: item.name
                    };
                });
                this.checkLeftListData();
            }).catch(err => {
                this.dialogVisible = false;
            });
        });
    }

    dialogOK() {
        return CustomerFlowGroupService.saveLinkRelation(this.modelId, _.map(this.rightDataList, item => item.id)).then((ret) => {
            this.dialogClose();
            this.$emit('dialogOK');
        });
    }
}
